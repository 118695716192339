<template>
  <div>       
    <b-row class="justify-content-center match-height mt-2 ml-2 mr-2 mb-2">
      <b-col xl="8" lg="8" md="6" sm="12">
          <b-card title="Acerca de Sosty">
            <b-card-text>
                <app-collapse>
                    <app-collapse-item title="Click Para Expandir">
                        <app-collapse-item title="¿Qué es Sosty?">
                        Sosty es una plataforma de ganadería colaborativa, permite conectar a productores con ganaderos digitales para que puedan comercializar ganado colaborativamente.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cuál es el propósito de Sosty?">
                        Democratizar la participación en proyectos de ganadería sostenible, conectando a consumidores con  productores que implementen buenas prácticas.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo funciona la plataforma Sosty?">
                        Sosty es un medio para que puedas participar en proyectos de colaboración empresarial agropecuarios, enfocados en ganadería. Compra, hazle seguimiento y vende ganado a través de Sosty.
                        </app-collapse-item>

                        <app-collapse-item title="¿Sosty brinda asesorías o recomendaciones de inversión?">
                        No, En Sosty no realizamos recomendaciones de inversión, somos una compañía comercializadora que facilita la conexión entre productores que están dispuestos a recibir ganado en participación, y personas que desean comprar ganado para llevarlos al aumento con Productores. Sosty brinda la información necesaria para que las partes decidan si quieren participar y crear un proyecto juntos.
                        </app-collapse-item>

                        <app-collapse-item title="¿Quién vigila a Sosty?">
                        Sosty S.A.S. al igual que cualquier sociedad mercantil de Colombia es vigilada por la Superintendencia de Sociedades. Sosty S.A.S. no es vigilada por la Superintendencia Financiera porque no es una entidad financiera, aseguradora, establecimiento de crédito o de capitalización, sino una sociedad dedicada a la comercialización de ganado
                        </app-collapse-item>
                    </app-collapse-item>                    
                </app-collapse>
            </b-card-text>
          </b-card>
      </b-col>
    </b-row>

    <b-row class="justify-content-center match-height mt-2 ml-2 mr-2 mb-2">
      <b-col xl="8" lg="8" md="6" sm="12">
          <b-card title="Acerca de NeoGanaderos">
            <b-card-text>
              <app-collapse>
                    <app-collapse-item title="Click Para Expandir">
                        <app-collapse-item title="¿Qué es un NeoGanadero?">
                        Un NeoGanadero o Ganadero Digital es una persona que desea comprar y vender ganado a través de Sosty participando en proyectos de colaboración a través de la plataforma Sosty.
                        </app-collapse-item>

                        <app-collapse-item title="¿Quién puede ser un NeoGanadero?">
                        Personas mayores de edad, con capacidad de firmar contratos.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué beneficios tiene un NeoGanadero?">
                        Si eres un NeoGanadero puedes encontrar a través de Sosty productores que implementen buenas prácticas, comprar, vender y asegurar tu ganado, hacerle seguimiento a tus animales, recibir acompañamiento de Sosty para que puedas ser un ganadero muy facilmente y recibir tus ganancias despues de que logren su objetivo
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué compromisos adquiere un NeoGanadero?">
                        Al ser un Neoganadero te estas convirtiendo en un ganadero real, el ganado que compres ingresa a tus activos y lo colocas en un contrato de colaboración a cambio de una participación, te comprometes a cumplir los Términos y Condiciones de Uso de la plataforma Sosty.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué costo tiene Sosty para el NeoGanadero?">
                        Sosty se encarga de tener un equipo técnico para comprar ganado de buena calidad a tu nombre, transportarlo hasta el proyecto que desees participar y asegurarlo a través de una compañía de seguros, adicionalmente colabora con su plataforma para que puedas hacerle un seguimiento a tus animales y brinda una asistencia técnica al proyecto. Sosty cobra una comisión de comercialización de compra y de venta de los animales y una tarifa de comisión de mandato la cual puedes ver al momento de participar en cada proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo puedo participar en un proyecto?">
                        Ingresa a la App de Sosty, observa los proyectos de los diferentes Productores y decide cuantos kilogramos quieres comprar, firma los contratos, realiza el pago y envía tu soporte de pago. Sosty se encargara de comprar el ganado a nombre de los NeoGanaderos y llevarlos al predio del Productor para que empieze el proyecto. Tú podrás hacerle seguimiento a todo lo que este sucediendo con el mismo e incluso ir a visitar el proyecto previa programación con Sosty y el Productor.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué documentos necesito para participar en Sosty?">
                        Al participar en Sosty estas realizando una compra de ganado y una participación en un contrato de colaboración junto a otros NeoGanaderos y un Productor. Los documentos que necesitamos para realizar tu participación son: Copia Documento de Identidad, RUT, Certificación Bancaria y firmas de Contratos.
                        </app-collapse-item>

                        <app-collapse-item title="¿Desde qué monto puedo participar?">
                        En cada proyecto puedes ver los kilogramos mínimos de compra, desde 100 kg puedes participar en un proyecto y no hay limite máximo.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo se cuál animal es mío?">
                        Al comprar en Sosty kilogramos de animal en pie, y participar en un proyecto de colaboración junto a otros NeoGanaderos y un Productor, tus kilogramos ingresan a participar dentro de un lote, luego no seras dueño de un animal en especifico sino de una participación del total del lote del proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo puedo hacerle seguimiento a los proyectos?">
                        Cuando empiece el proyecto en nuestra App puedes ver la información de que esta sucediendo con el mismo, de igual manera puedes programar visitas para ir a visitar el proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿En qué momento me puedo retirar de los proyectos Sosty?">
                        Una vez ingresas en un proyecto el mismo no se liquidara hasta que se termine, sin embargo puedes poner en venta tu participación a otros NeoGanaderos con el que llegues a un acuerdo en precio. Sin embargo, al participar en un proyecto, tu aporte en kilogramos se vuelve una participación del total y los que decidan comprar tu participación continuaran con las mismas obligaciones que tenias, y solo se liquidara el proyecto cuando este termine.
                        </app-collapse-item>

                        <app-collapse-item title="¿Esta inversión hace parte de mi Patrimonio?">
                        Sí! Al firmar un contrato de mandato con Sosty, el facturara la compra de los animales a nombre tuyo, y estos ingresaran a tus activos, tu seguirás siendo dueño de los kilogramos de animal en pie que compraste, sin embargo estarán en un contrato de colaboración junto a otros NeoGanaderos y el Productor que este ejecutando el proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Si le pasa algo algún animal pierdo mi dinero?">
                        Los animales tienen una póliza con una compañía aseguradora, el Productor y Sosty harán lo posible para que todo salga bien, sin embargo es posible que se materialicen riesgos en los proyectos. Si un animal muere o afecta el proyecto, esa afectación se descontara antes de repartir las utilidades del proyecto.
                        </app-collapse-item>
                    </app-collapse-item>                    
                </app-collapse>
            </b-card-text>
          </b-card>
      </b-col>
    </b-row>

    <b-row class="justify-content-center match-height mt-2 ml-2 mr-2 mb-2">
      <b-col xl="8" lg="8" md="6" sm="12">
          <b-card title="Acerca de Productores">
            <b-card-text>
                <app-collapse>
                    <app-collapse-item title="Click Para Expandir">
                        <app-collapse-item title="¿Quiénes son los Productores?">
                        Un Productor Sosty es una persona que desea recibir ganado de NeoGanaderos Sosty para tenerlos en un contrato de colaboración al aumento. Adicionalmente recibirás acompañamiento Técnico de Sosty para que pueda tener más probabilidades de sacar adelante su proyecto de colaboración
                        </app-collapse-item>

                        <app-collapse-item title="¿Quién puede ser un Productor Sosty? ¿Cuáles son los requisitos?">
                        Debes tener la infraestructura, pastos, agua, personal necesario para poder recibir los animales de los NeoGanaderos y adicionalmente implementar prácticas de ganadería regenerativa. Puedes solicitar una visita técnica de Sosty para que realice un diagnóstico y pueda tomar evidencia de tu proyecto, con el fin de que te puedas volver un productor verificado Sosty. Adicionalmente deberas aceptar que Sosty verifique en Listas Restrictivas que no tienes restricciones legales para poder participar en nuestros proyectos.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué beneficios tienen los Productores Sosty?">
                        Puedes maximizar los rendimientos de tus proyectos al tener más carga animal sin necesidad de endeudarte o concentrar tu patrimonio en tu capital de trabajo. Con los mismos costos fijos puedes recibir más animales y con algunos costos variables podrás tener mayor rendimiento por hectárea. Adicionalmente Sosty te brindara un acompañamiento técnico y podrás lograr conseguir mejores condiciones de venta a través de las negociaciones que realice Sosty.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué compromisos adquiere un Productor Sosty?">
                        Cuando decides participar en un proyecto de colaboración empresarial Sosty con uno o varios NeoGanaderos estas firmando un contrato donde te comprometes a recibir unos animales y a realizar una gestión por engordarlos, donde recibirás a cambio una participación sobre el aumento. Este ganado siempre será de los NeoGanaderos y tu los tendrás en calidad de Tenencia, sin embargo estarán bajo tu cuidado, donde te comprometes a cumplir las obligaciones adquiridas en el contrato y cumplir con los términos y condiciones de Sosty.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué costo tiene Sosty para los productores?">
                        Sosty cobra una tarifa por realizar el diagnóstico inicial la cual variara dependiendo de la ubicación de tu proyecto. Si tu proyecto es aprobado, Sosty cobrara unos valores de comercialización de compra y venta de los animales, los cuales podrás ver al momento de realizar la proyección financiera del proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo puedo crear un Proyecto?">
                        Primero debes tener una visita técnica de Sosty y cumplir los requerimientos mínimos para poder crear un proyecto. Si los cumples, Sosty te notificara para que juntos creemos el proyecto y busquemos NeoGanaderos que quieran participar en el.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué comisión puede cobrar un Productor?">
                        Sosty te ayudara a realizar la proyección financiera del proyecto, donde podrás decidir que participación cobraras a los NeoGanaderos por tener los animales contigo. Esta participación podrá estar entre el 60% y el 70%, donde podrás proyectar tus ganancias y ver la ganancia que tendrían los NeoGanaderos al participar en el. Recuerda que si tu comisión es muy alta, la rentabilidad de los NeoGanaderos disminuirá y puede disminuir la probabilidad de que quieran participar contigo.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué tipos de proyectos pueden crearse en Sosty?">
                        Actualmente puedes crear proyectos de levante y ceba, de machos o hembras de vacunos o bufalinos
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué documentos necesito para participar en Sosty?">
                        Sosty necesita verificar tu identidad, que el lugar donde se va a desarrollar el proyecto cumpla los requisitos legales, por lo tanto debes adjuntar una copia de tu documento de identidad, RUT, certificación bancaria, certificado de libertad y tradición, contrato de arrendamiento si el predio es arrendado, certificado de registro pecuario ante el ICA, certificado de uso del suelo de vocación pecuaria, ultimo soporte de vacunación si vas a tener ganado en tu finca adicional al ganado que vas a recibir a través de Sosty.
                        </app-collapse-item>
                    </app-collapse-item>                      
                </app-collapse>
            </b-card-text>
          </b-card>
      </b-col>
    </b-row>

    <b-row class="justify-content-center match-height mt-2 ml-2 mr-2 mb-2">
      <b-col xl="8" lg="8" md="6" sm="12">
          <b-card title="Acerca de los Proyectos sosty">
            <b-card-text>
                <app-collapse>
                    <app-collapse-item title="Click Para Expandir">
                        <app-collapse-item title="¿Qué es un proyecto Sosty?">
                        En un proyecto de colaboración empresarial agropecuario, donde un NeoGanadero decide participar con uno o varios NeoGanaderos que deseen comprar ganado a través de Sosty y llevarlo al predio del proyecto para que cumplan un objetivo y puedan repartir ganancias o perdidas del proyecto. Las partes entienden que el proyecto es de riesgo compartido y que ambas harán lo que este en su alcance para que tenga un resultado positivo, sin embargo las obligaciones de las partes serán de medio y no de resultado, y los rendimientos esperados no son garantizados, y son una estimación, de acuerdo a históricos de ganancia diaria de peso, y estimaciones de precio de compra y venta de los animales.
                        </app-collapse-item>

                        <app-collapse-item title="¿Quiénes participan en los proyectos?">
                        El Productor como ejecutor del proyecto, NeoGanaderos como aportantes del ganado y Sosty como coordinador entre las partes.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué tipos de proyectos hay?">
                        Actualmente estamos desarrollando proyectos de levante y ceba, de machos o hembras de vacunos o bufalinos.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo se determina el costo de kilogramo de cada proyecto?">
                        El Valor del kilogramo para participar en cada proyecto es un valor estimado de acuerdo a los precios de mercado actual en la zona donde se va a desarrollar el proyecto. Estos valores pueden ser inferiores o superiores al momento de realizar la compra, si son inferiores con los recursos aportados por los NeoGanaderos se podrán comprar más kilogramos, si es superior, menos kilogramos. Adicionalmente se estima un valor de flete por kilogramo, de aseguramiento con una compañía aseguradora y de comercialización. Estos valores son estimados y serán ajustados con el precio real y peso real de los animales aportados al proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Quién asume los costos de comercialización, flete, seguro?">
                        Estos valores son asumidos por los NeoGanaderos, sin embargo serán devueltos al momento de liquidar el proyecto a los NeoGanaderos como inversión inicial. Razón por la cual el Productor estimara su comisión de acuerdo a estos valores que se descontaran al momento de determinar la utilidad bruta del proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Quién vigila el proyecto?">
                        El Productor es el ejecutor del Proyecto y contara con el apoyo técnico de Sosty, sin embargo los NeoGanaderos podrán hacerle seguimiento al Proyecto a través de la plataforma e incluso visitando los predios para que pueda corroborar la información suministrada por el Productor.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo se estima la rentabilidad de los proyectos?">
                        La rentabilidad es un supuesto que depende de la duración del proyecto, es decir los meses que tardarían los animales en engordar hasta su peso objetivo, el precio estimado de compra y el precio estimado de venta. Estas variables son supuestos que no puede garantizar Sosty ni el Productor, sin embargo se tratan de proyectar valores conservadores para que el NeoGanadero pueda tener una idea de en cuanto podría vender sus animales y recibir de ganancia después de recuperar su inversión. El proyecto de colaboración es un proyecto de riesgo compartido tanto en las utilidades como en las perdidas.
                        </app-collapse-item>

                        <app-collapse-item title="¿Sosty me garantiza la rentabilidad de los proyectos?">
                        No. Sosty es un medio para que puedas conectarte con un Productor y colocar tu ganado al aumento con una mayor confianza, sin embargo Sosty no puede garantizar la rentabilidad del proyecto, debido a que realiza una gestión de medio y no de resultado.
                        </app-collapse-item>

                        <app-collapse-item title="¿Qué mecanismos usa Sosty para asegurar mi participación?">
                        Si bien toda inversión tienen un riesgo asociado, el riesgo de participar en Sosty es  bajo y está asociado a condiciones meteorológicas, precios de mercado,  externalidades relacionadas a  cada proyecto, tales como:  terrorismo, vandalismo, etc. Para mitigar estos riesgos, cada proyecto cuenta con sus respectivas pólizas. Adicionalmente, los proyectos están sujetos a un contrato de colaboración empresarial donde se aclaran las obligaciones y responsabilidades de los partes participantes.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo se distribuyen los riesgos del proyecto?">
                        Los riesgos se distribuyen de acuerdo a la participación de las partes. Sosty y el productor trataran de mitigar los riesgos, a través de acompañamiento técnico y el respaldo de una compañía aseguradora, sin embargo si se materializa un riesgo que no esta cubierto en la póliza, y ocurre no por negligencia del productor si no por fuerza mayor, este afectara la utilidad final del proyecto.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo funciona el Seguro de los animales?">
                        Los animales tendrán un seguro dado por una compañía aseguradora. La cobertura e información de la póliza se encuentra en los documentos adjuntos del proyecto. Sosty actuara como Tomador del seguro, y el asegurado será el productor. Adicionalmente Sosty será el beneficiario y se encargara en calidad de coordinador del proyecto la repartición de las indemnizaciones de acuerdo a la participación de las partes, descontando costos de transacción como por ejemplo de 4 x mil.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cuánto tiempo dura mi participación en el proyecto?">
                        Cada proyecto tiene una duración estimada de acuerdo a una Ganancia Diaria de Peso, la cual nos permite determinar un fecha probable para alcanzar la meta del proyecto. Cuando esta se cumpla se liquidara el proyecto y se repartirán las utilidades o perdidas de acuerdo a la participación de las partes.
                        </app-collapse-item>

                        <app-collapse-item title="¿Quién se encarga de vender los animales?">
                        Faltando 30 días para alcanzar la meta objetivo, el productor notificara a través de la plataforma para que las partes busquen el mejor postor, Sosty también buscara compradores al mejor precio posible, donde se aceptara la oferta a los 5 días siguientes del cierre del proyecto con el comprador que cumpla los requisitos y oferte el mayor valor de compra. Sin embargo, si Sosty desea comprar los animales tendrá preferencia siempre y cuando oferte al menos el mismo valor de compra que el mejor postor.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cómo se obtienen mis ganancias?">
                        Las ganancias serán determinadas por el Precio de Venta de los Animales, y la Cantidad de Kilogramos alcanzados por el proyecto. Este aumento permitirá obtener unas ganancias, pagar los costos del proyecto y repartir las utilidades del proyecto entre los participantes.
                        </app-collapse-item>

                        <app-collapse-item title="¿Cuándo se reciben las ganancias de los proyectos?">
                        Una vez liquidado el proyecto, recibes el dinero en la cuenta bancaria que tienes registrada en Sosty.
                        </app-collapse-item>
                    </app-collapse-item>                      
                </app-collapse>
            </b-card-text>
          </b-card>
      </b-col>
    </b-row> 

    <b-row class="justify-content-center match-height mt-2 ml-2 mr-2 mb-2">
      <b-col xl="8" lg="8" md="6" sm="12">
            <b-card title="Contáctanos">
                <b-card-text>
                    <b-row>
                        <b-col xl="12" lg="12" md="12" sm="12">
                            <b-button variant="primary" href="https://api.whatsapp.com/send?phone=573204357649" target="_blank" block>
                                <feather-icon icon="MessageCircleIcon" class="mr-50"/>
                                <span class="align-middle">Vía Whatsapp</span>
                            </b-button>     
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col xl="12" lg="12" md="12" sm="12">
                            Celular: <b>3204357649</b> - Email: <b>hola@sosty.co</b>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>        
        </b-col>            
    </b-row>
    
  </div>
</template>

<script>
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {  BRow, BCol, BCard, BCardText, BLink, BButton, } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BRow, BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      userType: '',
    }
  },
  created() {
    var userData = getUserData();
    this.userType = userData.userType;
  },
}
</script>

<style>

</style>
